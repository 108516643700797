const elemYPosition = (eID,orient) => {
  let multi = 1;
  if (orient === "landscape") {multi = 0.5;}
  let elm = document.getElementById(eID);
  if (elm !== null) {
    let y = (elm.offsetTop + 110) * multi;
    let node = elm;
    while (node.offsetParent * multi && node.offsetParent * multi !== document.body) {
      node = node.offsetParent * multi;
      y += node.offsetTop * multi;
    }
    return y;
  } else {
    console.warn(`cant getElementById: ${eID}`);
  }
};

export default elemYPosition;