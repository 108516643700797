import React from 'react';
import './style.css';
import {withRouter} from "react-router-dom";
import Header, {BackButton, LogoButton} from "../../components/Header/Header";
import AppLayout from "../../layouts/AppLayout/AppLayout";
import DonateLayout from "../../layouts/DonateLayout/DonateLayout";

const DonateScreen = () => {
  return (
    <>
      <Header
        title={"Пожертвование"}
        leftOption={<BackButton backHref={"/"}/>}
        rightOption={<LogoButton/>}
      />
      <AppLayout id={"donate"}>
        <DonateLayout/>
      </AppLayout>
    </>
  );
};

export default withRouter(DonateScreen);