import React from 'react';
import './style.css';
import {withRouter} from "react-router-dom";
import Header, {BackButton, LogoButton} from "../../components/Header/Header";
import AppLayout from "../../layouts/AppLayout/AppLayout";
import WhereLayout from "../../layouts/WhereLayout/WhereLayout";

const WhereScreen = () => {
  return (
    <>
      <Header
        title={"Как нас найти?"}
        leftOption={<BackButton backHref={"/"}/>}
        rightOption={<LogoButton/>}
      />
      <AppLayout id={"where"}>
        <WhereLayout/>
      </AppLayout>
    </>
  );
};

export default withRouter(WhereScreen);