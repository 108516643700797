import {configure, observable, action, decorate} from "mobx";
import Const from '../../utils/Const';
import $ from 'jquery';

configure({enforceActions: "observed"});

class MinistryStore {
  mocked = true; //process.env.NODE_ENV === "development";
  data = [];
  seq = [];
  isLoading = true;

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }

  loadData() {
    let fetchUrl;
    if (this.mocked) {
      fetchUrl = `${Const.baseUrl}/server/ministry.json?v=${new Date().getTime()}`;
    } else {
      fetchUrl = `${Const.baseUrl}/server/ministry_get.php?v=${new Date().getTime()}`;
    }

    $.get(fetchUrl)
      .done(action((response) => {
        const data = this.mocked ? response : JSON.parse(response);

        this.storeData(data);
        this.randomSeq(data);

        this.endLoading();
      }))
      .fail(action((e) => {
        this.endLoading();
      }));
  }

  storeData(data) {
    this.data = data;
  }

  randomSeq(data) {
    this.seq = data.map((item, index) => index).sort((a, b) => Math.random() - 0.5);
  }
}
decorate(MinistryStore, {
  data: observable,
  seq: observable,

  isLoading: observable,
  startLoading: action,
  endLoading: action,

  loadData: action,
  storeData: action,
  randomSeq: action,
});

const ministryStore= new MinistryStore();
export default ministryStore;