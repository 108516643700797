import React from 'react';
import './style.css';
import NoDataBlock from "../../components/NoDataBlock/NoDataBlock";

const NewsLayout = () => {
  return (
    <NoDataBlock cls={"news"} title={"Нет новостей"}/>
  );
};

export default NewsLayout;