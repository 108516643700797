import {configure, observable, action, decorate} from "mobx";
import Const from '../../utils/Const';
import $ from 'jquery';

configure({enforceActions: "observed"});

class CalendarStore {
  mocked = true; //process.env.NODE_ENV === "development";
  data = {
    schedule: {
      data: [],
      isLoading: true,
      category: "Служения",
    },
    readPlan: {
      data: [],
      isLoading: true,
      category: "План чтения",
    },
    events: {
      data: [],
      isLoading: true,
      category: "События",
    },
  };

  startLoading() {
    Object.entries(this.data).forEach(([key, value]) => {
      value.isLoading = true;
    });
  }

  endLoading() {
    Object.entries(this.data).forEach(([key, value]) => {
      value.isLoading = false;
    });
  }

  loadSchedule() {
    let fetchUrl;
    if (this.mocked) {
      fetchUrl = `${Const.baseUrl}/server/calendar_week.json?v=${new Date().getTime()}`;
    } else {
      fetchUrl = `${Const.baseUrl}/server/calendar_schedule_get.php?v=${new Date().getTime()}`;
    }

    $.get(fetchUrl)
      .done(action((response) => {
        const data = this.mocked ? response : JSON.parse(response);

        this.storeSchedule(data);
        this.data.schedule.isLoading = false;
      }))
      .fail(action((e) => {
        this.data.schedule.isLoading = false;
      }));
  }

  storeSchedule(data) {
    this.data = {
      ...this.data,
      schedule: {
        ...this.data.schedule,
        data
      }
    };
  }

  loadReadPlan() {
    let fetchUrl;
    if (this.mocked) {
      fetchUrl = `${Const.baseUrl}/server/reading_plan.json?v=${new Date().getTime()}`;
    } else {
      fetchUrl = `${Const.baseUrl}/server/calendar_readplan_get.php?v=${new Date().getTime()}`;
    }

    $.get(fetchUrl)
      .done(action((response) => {
        const data = this.mocked ? response : JSON.parse(response);

        this.storeReadPlan(data);
        this.data.readPlan.isLoading = false;
      }))
      .fail(action((e) => {
        this.data.readPlan.isLoading = false;
      }));
  }

  storeReadPlan(data) {
    this.data = {
      ...this.data,
      readPlan: {
        ...this.data.readPlan,
        data
      }
    };
  }

  loadEvents() {
    let fetchUrl;
    if (this.mocked) {
      fetchUrl = `${Const.baseUrl}/server/calendar_event.json?v=${new Date().getTime()}`;
    } else {
      fetchUrl = `${Const.baseUrl}/server/calendar_events_get.php?v=${new Date().getTime()}`;
    }

    $.get(fetchUrl)
      .done(action((response) => {
        const data = this.mocked ? response : JSON.parse(response);

        this.storeEvents(data);
        this.data.events.isLoading = false;
      }))
      .fail(action((e) => {
        this.data.events.isLoading = false;
      }));
  }

  storeEvents(data) {
    this.data = {
      ...this.data,
      events: {
        ...this.data.events,
        data
      }
    };
  }
}
decorate(CalendarStore, {
  data: observable,

  startLoading: action,
  endLoading: action,

  loadSchedule: action,
  storeSchedule: action,

  loadReadPlan: action,
  storeReadPlan: action,

  loadEvents: action,
  storeEvents: action,
});

const calendarStore = new CalendarStore();
export default calendarStore;