import React, {useEffect, useState} from "react";
import Const, {errorConst} from "../../utils/Const";
import {LoadingIco} from "../../components/Loader/Loader";
import ButtonBlock from "../../components/ButtonBlock/ButtonBlock";
import onlineStore from "../../stores/OnlineStore/OnlineStore";
import TitleBlock from "../../components/TitleBlock/TitleBlock";
import InputBlock from "../../components/InputBlock/InputBlock";
import {withRouter} from "react-router-dom";

const EditLayout = ({history}) => {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },[]);

  const video = history.location.state ? history.location.state.video : {};
  const mode = history.location.state ? history.location.state.mode : "add";

  const [isLoading, setIsLoading] = useState(false);
  const [resultMessage, setResultMessage] = useState();

  const now = new Date();

  const getMonth = (date) => {
    let month = "";
    switch (date) {
      case 0: month = "января"; break;
      case 1: month = "февраля"; break;
      case 2: month = "марта"; break;
      case 3: month = "апреля"; break;
      case 4: month = "мая"; break;
      case 5: month = "июня"; break;
      case 6: month = "июля"; break;
      case 7: month = "августа"; break;
      case 8: month = "сентября"; break;
      case 9: month = "октября"; break;
      case 10: month = "ноября"; break;
      case 11: month = "декабря"; break;
      default: break;
    }
    return month;
  };

  const editFields = [
    {
      title: "Название",
      name: "name",
      value: video.name ? video.name.replace(/\n/g, "\\n") : `Богослужение от\\n${now.getDate()} ${getMonth(now.getMonth())} ${now.getFullYear()}г`
    },
    {
      title: "Ссылка",
      name: "src",
      value: video.src
    },
    {
      title: "Дата",
      name: "date",
      value: video.date ? video.date : `${now.toISOString().slice(0,10).replace(/-/g,"-")} ${now.toLocaleTimeString().slice(0,5)}:00`
    },
    {
      title: "Категория",
      name: "type",
      value: video.type ? video.type : "Онлайн"
    },
  ];

  const [editValue, setEditValue] = useState(editFields.reduce((result, item) => {
    result[item.name] = item.value;
    return result;
  },{}));

  const submitPanel = (
    resultMessage !== Const.codeOK
      ? (
        <div className={"online-edit-submit-wrapper"}>
          {isLoading
            ? (
              <LoadingIco
                style={{
                  height: 60,
                  width: 60,
                }}
              />
            )
            : (
              <>
                <ButtonBlock
                  text={"Сохранить"}
                  style={{
                    background: "#31DD9F",
                    width: 400,
                  }}
                  onClick={() => {
                    const fetchData = async (mode) => {
                      switch (mode) {
                        case "add":
                          return onlineStore.insertData(editValue.name, editValue.date, editValue.src, editValue.type);
                        case "edit":
                          return onlineStore.editData(video.id_online, editValue.name, editValue.date, editValue.src, editValue.type);
                        default:
                          break;
                      }
                    };
                    setIsLoading(true);
                    fetchData(mode)
                      .then(() => {
                        setResultMessage(Const.codeOK);
                        //history.goBack();
                      })
                      .catch(() => {
                        setResultMessage(errorConst.RequestError);
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }}
                />
                <ButtonBlock
                  text={"Отмена"}
                  style={{
                    background: "#FF759E",
                    width: 400,
                  }}
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </>
            )
          }
        </div>
      )
      : (null)
  );

  const resultPanel = (
    isLoading
      ? (null)
      : (
        <div className={"s4"}
             style={resultMessage !== Const.codeOK ? {color: "red"} : {color: "#31DD9F"}}
        >
          {resultMessage}
        </div>
      )
  );

  return (
    <>
      {resultPanel}
      {submitPanel}
      {editFields.map((field, fieldIndex) => {
        return (
          <React.Fragment key={fieldIndex}>
            <TitleBlock
              text={field.title}
              align={"left"}
            />
            <InputBlock
              type={"text"}
              placeholder={field.title.toLowerCase()}
              value={field.value}
              onChange={(value) => {
                setEditValue((prevState) => {
                  return {
                    ...prevState,
                    [field.name]: value,
                  };
                });
              }}
              style={{
                textAlign: "center",
              }}
            />
          </React.Fragment>
        )
      })}
      {resultPanel}
      {submitPanel}
    </>
  );
};

export default withRouter(EditLayout);