import React from "react";
import '../BlockWrapper/style.css';
import './style.css';
import BlockWrapper from "../BlockWrapper/BlockWrapper";

const PromoBlock = () => {
  return (
    <BlockWrapper
      cls={"promo"}
      leftContent={
        <div id={"promo-text-body"}>
          <div id={"promo-text"} className={"s1"}>
            {"Центральная\nЦерковь ЕХБ\nг. Кемерово"}
          </div>
        </div>
      }
      middleContent={
        <div id={"promo-logo"}/>
      }
    />
  );
};

export default PromoBlock;