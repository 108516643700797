import React from 'react';
import {withRouter} from "react-router-dom";
import './style.css';
import Header, {BackButton, LogoButton} from "../../components/Header/Header";
import AppLayout from "../../layouts/AppLayout/AppLayout";
import NewsLayout from "../../layouts/NewsLayout/NewsLayout";

const NewsScreen = () => {
  return (
    <>
      <Header
        title={"События"}
        leftOption={<BackButton backHref={"/"}/>}
        rightOption={<LogoButton/>}
      />
      <AppLayout id={"news"}>
        <NewsLayout/>
      </AppLayout>
    </>
  );
};

export default withRouter(NewsScreen);