import React from 'react';
import {BrowserRouter as Router, Route, Switch, withRouter} from "react-router-dom";
import './style.css';
import Header, {BackButton, CustomButton, LogoButton} from "../../components/Header/Header";
import AppLayout from "../../layouts/AppLayout/AppLayout";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import {observer} from "mobx-react";
import authStore from "../../stores/AuthStore/AuthStore";
import AccountLayout from "../../layouts/AccountLayout/AccountLayout";
import AdminLayout from "../../layouts/AdminLayout/AdminLayout";
import {Colors, Themes} from "../../utils/Const";
import {ExitIco} from "../../components/Icons/Icons";

const AccountScreen = observer(() => {
  const {
    isLogin,
    isLoading,
    errorMessage,
    data,
  } = authStore;

  const handlerAuth = (login, password) => {
    const fetchData = async () => {
      authStore.authorization(login, password);
    };
    authStore.startLoading();
    fetchData().then(null);
  };

  const navStyles = {
    navBody : {
      background: Themes.admin.primaryColor,
      borderBottomColor: Themes.admin.secondaryColor,
    },
  };

  return (
    <>
      <Header
        title={""}
        styles={isLogin ? navStyles : undefined}
        leftOption={
          <BackButton
            backHref={isLogin ? undefined : "/"}
          />
        }
        rightOption={isLogin
          ? (
            <CustomButton
              icon={
                <ExitIco
                  color={Colors.white}
                  patternColor={Colors.white}
                />}
              scale={80}
              onClick={() => {
                authStore.setIsLogin(false);
              }}
            />
          )
          : (<LogoButton/>)
        }
      />
      <AppLayout id={"account"}>
        {isLogin
          ? (
            <Router basename={"/account"}>
              <Switch>
                <Route exact path={["/", ""]} component={() => <AccountLayout data={data}/>}/>
                <Route path="/admin" component={() => <AdminLayout/>}/>
                <Route path="*" component={null}/>
              </Switch>
            </Router>
          )
          : (
            <AuthLayout
              onAuth={(login, password) => {
                handlerAuth(login, password);
              }}
              isLoading={isLoading}
              error={errorMessage}
            />
          )
        }
      </AppLayout>
    </>
  );
});

export default withRouter(AccountScreen);