import React, {useEffect} from 'react';
import './style.css';
import ButtonBlock from "../../components/ButtonBlock/ButtonBlock";
import {observer} from "mobx-react";
import donateStore from "../../stores/DonateStore/DonateStore";
import {LoadingIco} from "../../components/Loader/Loader";

const DonateLayout = observer(() => {
  const {
    data,
    isLoading,
    errorMessage,
    isPaying,
    confirmationToken,
  } = donateStore;

  const handlerInitPay = () => {
    if (data.amount.value) {
      const fetchData = async () => {
        await donateStore.initPayment();
      };
      donateStore.startLoading();
      fetchData().then(null);
    } else {
      //TODO ValidateInput
    }
  };

  const renderPaymentForm = (token) => {
    const checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token: token,
      return_url: 'https://ehbkem.ru/donate',
      error_callback(error) {
        console.log(error);
      }
    });

    checkout.render('yoo-kassa-form');
  };

  useEffect(() => {
    if (isPaying) {
      renderPaymentForm(confirmationToken);
    }
  },[isPaying, confirmationToken]);

  let hintText = [
    "Вы можете внести добровольные пожертвования или принять материальное участие в любом из наших служений, перечислив любую сумму через онлайн форму",
  ];
  return (
    <>
      <div id={"pay-hint-wrapper"}>
        <div id={"pay-hint-body"} className={"s4"}>
          {hintText.join("\n\n")}
        </div>
      </div>
      {/*<div id={"ya-money-wrapper"}>
        <iframe
          id={"ya-money"}
          src={"https://money.yandex.ru/quickpay/shop-widget?writer=buyer&targets=&targets-hint=&default-sum=&button-text=14&payment-type-choice=on&hint=&successURL=ehbkem.ru&quickpay=shop&account=410011102196258"}
          allowtransparency="true"
          scrolling="no"
        />
      </div>*/}
      {isPaying
        ? (
          <div id={"yoo-kassa-wrapper"}>
            <div id={"yoo-kassa-body"}>
              <div id={"yoo-kassa-form"}/>
            </div>
          </div>
        )
        : (
          <div id={"payment-init-form-wrapper"}>
            <div id={"payment-init-form"}>
              <div id={"yoo-kassa-logo"}/>
              <div className={"payment-init-form-input-title"}
              >{"Назначение перевода"}</div>
              <input
                className={"payment-init-form-input"}
                type={"text"}
                disabled={isLoading}
                value={data.description}
                onInput={(event) => donateStore.setDataDescription(event.target.value)}
              />
              <div className={"payment-init-form-input-title"}
              >{"Сумма"}</div>
              <div id={"payment-init-form-amount"}>
                <input
                  className={"payment-init-form-input"}
                  style={{width: 250}}
                  type={"text"}
                  disabled={isLoading}
                  placeholder={"Руб."}
                  value={data.amount.value}
                  onInput={(event) => donateStore.setAmountValue(event.target.value)}
                />
                {isLoading
                  ? (<LoadingIco style={{width: 48, height: 48, margin: "0 107px"}}/>)
                  : (
                    <ButtonBlock
                      cls={"payment-init-form"}
                      text={"Пожертвовать"}
                      onClick={() => handlerInitPay()}
                    />
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      <div id={"pay-ty-wrapper"}>
        <div id={"pay-ty-body"} className={"s4"}>
          {"\u00ABБог же силен обогатить вас всякою благодатью, чтобы вы, всегда и во всем имея всякое довольство, были богаты на всякое доброе дело\u00BB"}
          <div id={"ty-verse"} className={"s4"}>
            2-Коринфянам 9:8
          </div>
        </div>
      </div>
      <div id={"origin-pay-info-wrapper"}>
        <div id={"origin-pay-info-body"} className={"s4"}>
          Вы также можете сделать перевод самостоятельно:<br/><br/>
          <b>ОГРН: 1034200007291</b><br/><br/>
          <b>МЕСТНАЯ РЕЛИГИОЗНАЯ ОРГАНИЗАЦИЯ ЦЕРКОВЬ ЕВАНГЕЛЬСКИХ ХРИСТИАН-БАПТИСТОВ Г. КЕМЕРОВО</b><br/><br/>
          <b>ИНН: 4205004998</b><br/><br/>
          <b>КПП: 420501001</b><br/><br/>
          <b>Р/С: 40703810626030108215</b><br/><br/>
          <b>БИК: 043207612</b><br/><br/>
          <b>Назначение платежа: </b>"Добровольное пожертвование на уставную деятельность"<br/><br/>
        </div>
      </div>
    </>
  );
});

export default DonateLayout;