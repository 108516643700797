import React from 'react';
import './style.css';

const AppLayout = ({children, id="app", styles={}}) => {
  const {
    appWrapper={},
    appBody={},
  } = styles;

  return (
    <div id={"app-wrapper"}
         style={appWrapper}
    >
      <div id={id} className={"app"}
           style={appBody}
      >
        {children}
      </div>
    </div>
  );
};

export default AppLayout;