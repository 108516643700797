import React from 'react';
import './style.css';

const PageBtn = ({key, cls, text, i="", active, onChange}) => {
  return (
    <div
      key={key}
      id={`p${i}`} className={"page-body"}
      onClick={() => {
        if (cls === "") {
          onChange(i);
        }
      }}
    >
      {{
        "more": (
          <form
            className={`page-btn ${cls} ${active ? "active" : ""}`}
            onSubmit={(event) => {
              let elem = event.target;
              let page = parseInt(elem.elements.text.value)-1;
              if (isNaN(page)) {
                elem.elements.text.value = null;
              } else {
                elem.elements.text.value = null;
                onChange(page);
              }

              event.preventDefault();
            }}
          >
            <input
              className={"input-btn-text s2"}
              name={"text"}
              type={"text"}
              placeholder={text}
            />
          </form>
        ),
        "": (
          <div className={`page-btn ${cls} ${active ? "active" : ""}`}>
            <div className={"page-btn-text s2"}>
              {text}
            </div>
          </div>
        ),
      }[cls]}
    </div>
  );
};

const Paginator = ({len, itemGroup, page=0, onChange=()=>{}}) => {
  let numOfPage = Math.ceil(len/itemGroup);
  let maxPage = 5;
  let minPage = maxPage - 2;
  let btnCount = 0;
  let readyBtns = Array.apply(0, Array(numOfPage)).map((item, p) => {
    const active = p === page && numOfPage !== 1;
    const btnTypes = {
      number: {
        key: p,
        cls: "",
        text: p+1,
        i: p,
        active,
        onChange,
      },
      input: {
        key: p,
        cls: "more",
        text: "\u00B7\u00B7\u00B7",
        i: undefined,
        active,
        onChange,
      }
    };
    let readyBtn = false;

    if (numOfPage === 1) {
      readyBtn = false;
    } else if (numOfPage === 6) {
      readyBtn = btnTypes["number"];
      btnCount++;
    } else if (page < maxPage-1) { //слева
      if (p >= numOfPage-1) {
        readyBtn = btnTypes["number"];
        btnCount++;
      } else if (p === maxPage) {
        readyBtn = btnTypes["input"];
        btnCount++;
      } else if (p > maxPage) {
        readyBtn = false;
      } else {
        readyBtn = btnTypes["number"];
        btnCount++;
      }
    } else if (page > numOfPage-maxPage) { //справа
      if (p >= numOfPage-maxPage) {
        readyBtn = btnTypes["number"];
        btnCount++;
      } else if (p === numOfPage-maxPage-1) {
        readyBtn = btnTypes["input"];
        btnCount++;
      } else if (p > 0 && p < numOfPage-maxPage-1) {
        readyBtn = false;
      } else {
        readyBtn = btnTypes["number"];
        btnCount++;
      }
    } else { //между
      if (p === 0 || p === numOfPage-1) {
        readyBtn = btnTypes["number"];
        btnCount++;
      } else if (p === 1 || p === numOfPage-2) {
        readyBtn = btnTypes["input"];
        btnCount++;
      } else if (p >= page-1 && p < page+minPage-1) {
        readyBtn = btnTypes["number"];
        btnCount++;
      }
    }

    return readyBtn ? PageBtn(readyBtn) : null;
  });

  return (
    <div
      className={"page-panel"}
      style={numOfPage !== 1 ? {width: 130 * btnCount} : {}}
    >
      {readyBtns.map((btn) => {
        return btn;
      })}
    </div>
  );
};

export default Paginator;