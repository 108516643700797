import React from 'react';
import './style.css';
import {accMenu} from "../../stores/AppStore/AppStore";
import TitleBlock from "../../components/TitleBlock/TitleBlock";
import MenuBlock from "../../components/MenuBlock/MenuBlock";
import {withRouter} from "react-router-dom";
import {Themes} from "../../utils/Const";

const AccountLayout = ({history}) => {
  return (
    <>
      {accMenu.map((menu, menuIndex) => {
        return (
          <React.Fragment key={menuIndex}>
            <TitleBlock
              cls={"menu"}
              text={menu.category}
              align={"left"}
            />
            {menu.sections.map((section, sectionIndex) => {
              return (
                <MenuBlock
                  key={sectionIndex}
                  ico={section.ico}
                  name={section.title}
                  onClick={() => {
                    history.push(section.href);
                  }}
                  behavior={{
                    common: {
                      hover: {
                        background: Themes.account.primaryColor
                      },
                    },
                  }}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default withRouter(AccountLayout);