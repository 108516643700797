import React, {useEffect, useState} from 'react';
import './style.css';
import Loader from "../../components/Loader/Loader";
import SearchBar from "../../components/SearchBar/SearchBar";
import Paginator from "../../components/Paginator/Paginator";
import VideoBlock from "../../components/VideoBlock/VideoBlock";
import TitleBlock from "../../components/TitleBlock/TitleBlock";
import NoDataBlock from "../../components/NoDataBlock/NoDataBlock";

const SearchVideo = (keyword, data) => {
  let searchString = keyword.toLowerCase().replace(/[^A-Za-zА-Яа-яЁё0-9]/g, "");

  return data.reduce((result, item) => {
    let str = item.name.toLowerCase().replace(/[^A-Za-zА-Яа-яЁё0-9]/g, "");
    if (str.indexOf(searchString) > -1) {
      result.push(item);
    }

    return result;
  },[]);
};

const handlerChangePage = (setPage, page) => {
  setPage(page);
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};

const OnlineLayout = ({isLoading, data, manageTools={}}) => {
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [filteredVideo, setFilteredVideo] = useState(data);

  useEffect(() => {
    setFilteredVideo(data)
  },[data]);

  let itemGroup = 3;

  let sortedVideo = filteredVideo.sort((a, b) => {
    return ('' + b.date).localeCompare(a.date);
  });

  let videoCategory = sortedVideo.map((item) => item.type);
  videoCategory = Array.from(new Set(videoCategory));

  let startNum = page*itemGroup;
  let endNum = page*itemGroup+itemGroup;
  endNum = endNum>=sortedVideo.length ? sortedVideo.length : endNum;
  let visibleVideo = sortedVideo.slice(startNum, endNum);

  let readyVideo = videoCategory.map((category) => {
    const matchVideo = visibleVideo.filter((video) => video.type === category);

    return {
      name: category,
      videos: matchVideo
    };
  });

  const PaginatorComponent = <Paginator
    len={sortedVideo.length}
    itemGroup={itemGroup}
    page={page}
    onChange={(page) => {
      handlerChangePage(setPage, page);
    }}
  />;

  return (
    isLoading
      ? (
        <div id={"empty-online"}>
          <Loader/>
        </div>
      )
      : (data.length>0
        ? (
          <>
            <SearchBar
              onClick={(value) => {
                setKeyword(value);
                setPage(0);
                setFilteredVideo(
                  SearchVideo(value, data)
                );
              }}
            />
            {PaginatorComponent}
            {manageTools.enabled
              ? (
                !sortedVideo.length>0
                && keyword
              )
                ? (null)
                : (
                  <div className={"video-category"}>
                    <TitleBlock
                      cls={"online"}
                      text={"Добавить"}
                      align={"left"}
                    />
                    <VideoBlock
                      video={{name:""}}
                      manageTools={manageTools}
                    />
                  </div>
                )
              : (null)
            }
            {sortedVideo.length>0
              ? readyVideo.map((category, categoryIndex) => {
                return (
                  category.videos.length>0
                    ? (
                      <div
                        key={categoryIndex}
                        className={"video-category"}
                      >
                        <TitleBlock
                          cls={"online"}
                          text={category.name}
                          align={"left"}
                        />
                        {category.videos.map((video, videoIndex) => {
                          return (
                            <VideoBlock
                              key={videoIndex}
                              video={video}
                              manageTools={manageTools}
                            />
                          );
                        })}
                      </div>
                    )
                    : (null)
                )
              })
              : keyword
                ? (<NoDataBlock cls={"found"} title={"По вашему запросу\nничего не найдено"}/>)
                : (<></>)
            }
            {PaginatorComponent}
          </>
        )
        : (<NoDataBlock cls={"online"} title={"Трансляций нет"}/>)
      )
  );
};

export default OnlineLayout;