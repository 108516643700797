import React from "react";
import {action, configure, decorate, observable} from "mobx";
import Const, {Colors, Themes} from "../../utils/Const";
import $ from "jquery";
import {CanvasForIco, ControlsIco, UserIco} from "../../components/Icons/Icons";

export const appMenu = [
  {
    "category":"Медиа",
    "sections":[
      {
        "name" : "online",
        "href" : "/online",
        "ico" : "/img/ico/youtube.svg?v=0.004",
        "title" : "Онлайн\nтрансляции"
      },
      {
        "name" : "audio",
        "href" : "/audio",
        "ico" : "/img/ico/micro.svg?v=0.002",
        "title" : "Аудио\nПроповеди"
      }
    ]
  },
  {
    "category":"Новости",
    "sections":[
      {
        "name" : "news",
        "href" : "/news",
        "ico" : "/img/ico/news.svg?v=0.001",
        "title" : "События\nНа неделе"
      },
      {
        "name" : "calendar",
        "href" : "/calendar",
        "ico" : "/img/ico/calendar.svg?v=0.002",
        "title" : "Календарь\nМероприятий"
      }
    ]
  },
  {
    "category":"Служения",
    "sections":[
      {
        "name" : "ministry",
        "href" : "/ministry",
        "ico" : "/img/ico/ministry.svg?v=0.002",
        "title" : "Служения\nЦеркви"
      },
      {
        "name" : "donate",
        "href" : "/donate",
        "ico" : "/img/ico/donate.svg?v=0.002",
        "title" : "Добровольное\nПожертвование"
      }
    ]
  },
  {
    "category":"О нас",
    "sections":[
      {
        "name" : "where",
        "href" : "/where",
        "ico" : "/img/ico/geolocation.svg?v=0.002",
        "title" : "Как нас\nНайти?"
      },
      /*{
          "name" : "about",
          "href" : "/inprogress",
          "ico" : "/img/ico/archive.svg?v=0.003",
          "title" : "История\nЦеркви"
      }*/
    ]
  },
  /*{
      "category":"Обратная связь",
      "sections":[
          {
              "name" : "question",
              "href" : "/inprogress",
              "ico" : "/img/ico/mail.svg?v=0.002",
              "title" : "Вопрос\nПастору"
          },
          {
              "name" : "pray",
              "href" : "/inprogress",
              "ico" : "/img/ico/praying.svg?v=0.002",
              "title" : "Помолитесь\nЗа меня"
          }
      ]
  }*/
];

export const accMenu = [
  {
    "category":"Администрирование",
    "sections":[
      {
        "name" : "admin",
        "href" : "/admin",
        "ico" : (
          <CanvasForIco>
            <ControlsIco
              color={Themes.account.primaryColor}
              patternColor={Colors.white}
            />
          </CanvasForIco>
        ),
        "title" : "Панель управления"
      },
    ]
  },
  {
    "category":"Настройки",
    "sections":[
      {
        "name" : "settings",
        "href" : "/settings",
        "ico" : (
          <CanvasForIco innerSize={110}>
            <UserIco
              color={Colors.black}
              patternColor={Themes.account.primaryColor}
            />
          </CanvasForIco>
        ),
        "title" : "Профиль"
      },
    ]
  },
];

export const adminMenu = [
  {
    "category":"Медиа",
    "sections":[
      {
        "name" : "online",
        "enabled" : true,
        "href" : "/online",
        "ico" : "/img/ico/youtube.svg?v=0.004",
        "title" : "Онлайн\nтрансляции"
      },
      {
        "name" : "audio",
        "enabled" : false,
        "href" : "/audio",
        "ico" : "/img/ico/micro.svg?v=0.002",
        "title" : "Аудио\nПроповеди"
      }
    ]
  },
  {
    "category":"Новости",
    "sections":[
      {
        "name" : "news",
        "enabled" : false,
        "href" : "/news",
        "ico" : "/img/ico/news.svg?v=0.001",
        "title" : "События\nНа неделе"
      },
      {
        "name" : "calendar",
        "enabled" : false,
        "href" : "/calendar",
        "ico" : "/img/ico/calendar.svg?v=0.002",
        "title" : "Календарь\nМероприятий"
      }
    ]
  },
  {
    "category":"Служения",
    "sections":[
      {
        "name" : "ministry",
        "enabled" : false,
        "href" : "/ministry",
        "ico" : "/img/ico/ministry.svg?v=0.002",
        "title" : "Служения\nЦеркви"
      },
      {
        "name" : "donate",
        "enabled" : false,
        "href" : "/donate",
        "ico" : "/img/ico/donate.svg?v=0.002",
        "title" : "Добровольное\nПожертвование"
      }
    ]
  },
  {
    "category":"О нас",
    "sections":[
      {
        "name" : "where",
        "enabled" : false,
        "href" : "/where",
        "ico" : "/img/ico/geolocation.svg?v=0.002",
        "title" : "Как нас\nНайти?"
      },
      /*{
          "name" : "about",
          "href" : "/inprogress",
          "ico" : "/img/ico/archive.svg?v=0.003",
          "title" : "История\nЦеркви"
      }*/
    ]
  },
  /*{
      "category":"Обратная связь",
      "sections":[
          {
              "name" : "question",
              "href" : "/inprogress",
              "ico" : "/img/ico/mail.svg?v=0.002",
              "title" : "Вопрос\nПастору"
          },
          {
              "name" : "pray",
              "href" : "/inprogress",
              "ico" : "/img/ico/praying.svg?v=0.002",
              "title" : "Помолитесь\nЗа меня"
          }
      ]
  }*/
];

configure({enforceActions: "observed"});

class AppStore {
  mocked = process.env.NODE_ENV === "development";
  ads = {};

  loadAds(screen) {
    const fetchUrl = `${Const.baseUrl}/server/ads_get.php?v=${new Date().getTime()}`;

    $.post(fetchUrl, {
      screen,
    })
      .done(action((response) => {
        const data = this.mocked ? response : JSON.parse(response);

        this.storeAds(data);
      }))
      .fail(action((e) => {
        console.error(e);
      }));
  }

  storeAds(data) {
    this.ads = data;
  }
}
decorate(AppStore, {
  ads: observable,

  loadAds: action,
  storeAds: action,
});

const appStore = new AppStore();
export default appStore;