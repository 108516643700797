import {configure, observable, action, decorate} from "mobx";
import Const from '../../utils/Const';
import $ from 'jquery';

configure({enforceActions: "observed"});

class OnlineStore {
  mocked = process.env.NODE_ENV === "development";
  data = [];
  isLoading = true;

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }

  loadData() {
    let fetchUrl;
    if (this.mocked) {
      fetchUrl = `${Const.baseUrl}/server/youtube.json?v=${new Date().getTime()}`;
    } else {
      fetchUrl = `${Const.baseUrl}/server/youtube_get.php?v=${new Date().getTime()}`;
    }

    $.get(fetchUrl)
      .done(action((response) => {
        const data = this.mocked ? response : JSON.parse(response);

        this.storeData(data);
        this.endLoading();
      }))
      .fail(action((e) => {
        this.endLoading();
      }));
  }

  storeData(data) {
    this.data = data;
  }

  insertData(name, date, src, type) {
    const fetchUrl = `${Const.baseUrl}/server/youtube_post.php?v=${new Date().getTime()}`;

    return new Promise((resolve, reject) => {
      $.post(fetchUrl, {
        name,
        date,
        src,
        type
      })
        .done(action((response) => {
          const data = this.mocked ? response : JSON.parse(response);

          console.log(data);
          resolve(data);
        }))
        .fail(action((e) => {
          console.error(e);
          reject(e);
        }));
    });
  }

  deleteData(id) {
    const fetchUrl = `${Const.baseUrl}/server/youtube_delete.php?v=${new Date().getTime()}`;

    return new Promise((resolve, reject) => {
      $.post(fetchUrl, {
        id
      })
        .done(action((response) => {
          const data = this.mocked ? response : JSON.parse(response);

          console.log(data);
          resolve(data);
        }))
        .fail(action((e) => {
          console.error(e);
          reject(e);
        }));
    });
  }

  editData(id, name, date, src, type) {
    const fetchUrl = `${Const.baseUrl}/server/youtube_edit.php?v=${new Date().getTime()}`;

    return new Promise((resolve, reject) => {
      $.post(fetchUrl, {
        id,
        name,
        date,
        src,
        type
      })
        .done(action((response) => {
          const data = this.mocked ? response : JSON.parse(response);

          console.log(data);
          resolve(data);
        }))
        .fail(action((e) => {
          console.error(e);
          reject(e);
        }));
    });
  }
}
decorate(OnlineStore, {
  data: observable,

  isLoading: observable,
  startLoading: action,
  endLoading: action,

  loadData: action,
  setData: action,

  insertData: action,

  deleteData: action,

  editData: action,
});

const onlineStore = new OnlineStore();
export default onlineStore;