import {configure, observable, action, decorate} from "mobx";
import Const, {errorConst} from '../../utils/Const';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';

configure({enforceActions: "observed"});

class DonateStore {
  data = {
    amount: {
      value: undefined,
      currency: "RUB"
    },
    confirmation: {
      type: "embedded"
    },
    capture: true,
    description: "Добровольное пожертвование"
  };

  isLoading = false;
  errorMessage = "";

  confirmationToken = undefined;
  isPaying = false;

  uuid = uuidv4();

  setDataDescription(text) {
    return null;

    /*this.data = {
      ...this.data,
      description: text
    }*/
  }

  setAmountValue(value) {
    const isValid = new RegExp("^[0-9]+$").test(value) || value === "";
    const newValue = isValid ? value : this.data.amount.value ? this.data.amount.value : "";

    this.data = {
      ...this.data,
      amount: {
        ...this.data.amount,
        value: newValue,
      }
    };
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }

  initPayment() {
    let fetchUrl = `${Const.baseUrl}/server/yookassa_get.php?v=${new Date().getTime()}`;

    $.post(fetchUrl, {
      data: JSON.stringify(this.data),
      key: this.uuid,
    })
      .done(action((response) => {
        console.log(response);
        const data = JSON.parse(response);

        if (data.type === "error") {
          this.isLoading = false;
          this.errorMessage = data.description;
          this.isPaying = false;
        } else {
          this.confirmationToken = data.confirmation.confirmation_token;
          this.isLoading = false;
          this.errorMessage = "";
          this.isPaying = true;
        }
      }))
      .fail(action((e) => {
        console.error(e);
        this.isLoading = false;
        this.errorMessage = errorConst.RequestError;
        this.isPaying = false;
      }));
  }
}
decorate(DonateStore, {
  data: observable,

  isLoading: observable,
  errorMessage: observable,

  confirmationToken: observable,
  isPaying: observable,

  uuid: observable,

  setDataDescription: action,
  setAmountValue: action,

  startLoading: action,
  endLoading: action,
  initPayment: action,
});

const donateStore = new DonateStore();
export default donateStore;