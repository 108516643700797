import React, {useEffect} from 'react';
import './style.css';
import {withRouter} from "react-router-dom";
import Header, {BackButton, LogoButton} from "../../components/Header/Header";
import AppLayout from "../../layouts/AppLayout/AppLayout";
import MinistryLayout from "../../layouts/MinistryLayout/MinistryLayout";
import ministryStore from "../../stores/MinistryStore/MinistryStore";
import {observer} from "mobx-react";

const MinistryScreen = observer(() => {
  useEffect(() => {
    const fetchData = async () => {
      await ministryStore.loadData();
    };
    ministryStore.startLoading();
    fetchData().then(null);
  }, []);

  return (
    <>
      <Header
        title={"Служения"}
        leftOption={<BackButton backHref={"/"}/>}
        rightOption={<LogoButton/>}
      />
      <AppLayout id={"ministry"}>
        <MinistryLayout
          isLoading={ministryStore.isLoading}
          data={ministryStore.data}
          seq={ministryStore.seq}
        />
      </AppLayout>
    </>
  );
});

export default withRouter(MinistryScreen);