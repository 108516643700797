import React, {useState} from 'react';
import './style.css';

const SearchBar = ({keyword="", onClick=()=>{}}) => {
  const [value, setValue] = useState(keyword);

  return (
    <div id={"search-body"}>
      <div id={"search-ico"}/>
      <input id={"search-input"}
             defaultValue={keyword}
             onChange={(event) => {
               setValue(event.target.value);
             }}
      />
      <div id={"search-btn"}
           onClick={() => {
             onClick(value);
           }}
      >
        <div id={"search-btn-text"} className={"s3"}>
          Поиск
        </div>
      </div>
    </div>
  );
};

export default SearchBar;