import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import './style.css';
import onlineStore from "../../stores/OnlineStore/OnlineStore";
import Header, {BackButton, LogoButton} from "../../components/Header/Header";
import AppLayout from "../../layouts/AppLayout/AppLayout";
import OnlineLayout from "../../layouts/OnlineLayout/OnlineLayout";

const OnlineScreen = observer(() => {
  useEffect(() => {
    const fetchData = async () => {
      await onlineStore.loadData();
    };
    onlineStore.startLoading();
    fetchData().then(null);
  }, []);

  return (
    <>
      <Header
        title={"Онлайн трансляция"}
        leftOption={<BackButton backHref={"/"}/>}
        rightOption={<LogoButton/>}
      />
      <AppLayout id={"online"}>
        <OnlineLayout
          isLoading={onlineStore.isLoading}
          data={onlineStore.data}
        />
      </AppLayout>
    </>
  );
});

export default withRouter(OnlineScreen);