import {configure, observable, action, decorate} from "mobx";
import Const, {errorConst} from '../../utils/Const';
import $ from 'jquery';

configure({enforceActions: "observed"});

class AuthStore {
  mocked = process.env.NODE_ENV === "development";
  isLogin = sessionStorage.getItem("isLogin") === "true";
  data = {};
  isLoading = false;
  errorMessage = null;

  startLoading() {
    this.isLoading = true;
    this.setError(null);
  }

  endLoading() {
    this.isLoading = false;
  }

  setError(message) {
    this.errorMessage = message;
  }

  authorization(login, password) {
    let fetchUrl, method;
    if (this.mocked) {
      fetchUrl = `${Const.baseUrl}/server/auth.json?v=${new Date().getTime()}`;
      method = "get";
    } else {
      fetchUrl = `${Const.baseUrl}/server/auth_get.php?v=${new Date().getTime()}`;
      method = "post"
    }

    $[method](fetchUrl, {
      login,
      password,
    })
      .done(action((response) => {
        const data = this.mocked ? response : JSON.parse(response);

        this.storeData(data);
        this.setIsLogin(true);

        this.setError(null);
        this.endLoading();
      }))
      .fail(action((e) => {
        this.setIsLogin(false);
        this.setError(errorConst.RequestError);

        this.endLoading();
      }));
  }

  storeData(data) {
    this.data = {
      idUser: data.id_user,
    };
  }

  setIsLogin(value) {
    this.isLogin = value;
    sessionStorage.setItem("isLogin", value);
  }
}
decorate(AuthStore, {
  isLogin: observable,
  data: observable,
  isLoading: observable,

  startLoading: action,
  endLoading: action,
  setError: action,

  authorization: action,
  setIsLogin: action,
});

const authStore = new AuthStore();
export default authStore;