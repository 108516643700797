import React from 'react';
import './style.css';
import TitleBlock from "../TitleBlock/TitleBlock";

const getCategoryID = (type) => {
  let ID = "";
  switch(type) {
    case "Онлайн": ID = "online";break;
    case "Архив": ID = "archive";break;
    default: break;
  }
  return ID;
};

const VideoBlock = ({video, manageTools={}}) => {
  let categoryID = getCategoryID(video.type);
  let videoName = video.name;

  if (video.src === "" && categoryID === "online") {
    let strDate = video.date.split(/[- :]/);
    let videoDate = new Date(strDate[0], strDate[1]-1, strDate[2], strDate[3], strDate[4], strDate[5]);

    let videoDay = ""+videoDate.getDate();
    while(videoDay.length<2){
      videoDay="0"+videoDay;
    }
    let videoMonth = ""+(videoDate.getMonth()+1);
    while(videoMonth.length<2){
      videoMonth="0"+videoMonth;
    }
    let videoMinutes = ""+videoDate.getMinutes();
    while(videoMinutes.length<2){
      videoMinutes="0"+videoMinutes;
    }
    videoName = "Трансляция начнется\n"+videoDay+"."+videoMonth+"."+videoDate.getFullYear()+" в "+videoDate.getHours()+":"+videoMinutes;
  }

  const videoHaveSrc = video.src !== undefined && video.src !== null;

  return (
    <div className={"player-container"}>
      {videoName
        ? (
          <TitleBlock
            cls={"video"}
            text={videoName}
            align={"middle"}
          />
        )
        : (null)
      }
      <div className={"player-wrapper"}>
        {manageTools.enabled
          ? videoHaveSrc
            ? (
              <div className={"player-control-wrapper"}>
                <div id={"control-edit"} className={"player-control"}
                     onClick={() => manageTools.editVideo(video)}
                />
                <div id={"control-delete"} className={"player-control"}
                     onClick={() => manageTools.deleteVideo(video)}
                />
              </div>
            )
            : (null)
          : (null)
        }
        <div className={`player-body ${categoryID}`}>
          {videoHaveSrc && video.src !== ""
            ? (
              <iframe
                className={"player"}
                title={videoName}
                src={`https://www.youtube.com/embed/${video.src}`}
                allow={"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"}
                allowFullScreen={true}
              />
            )
            : video.src === ""
              ? (
                <div className={"player"}/>
              )
              : (
                <div className={"player-add"}
                     onClick={() => {
                       if (manageTools.addVideo) manageTools.addVideo(video);
                     }}
                     style={{cursor: "pointer"}}
                />
              )
          }
        </div>
      </div>
    </div>
  );
};

export default VideoBlock;