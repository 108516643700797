import React, {useEffect, useState} from 'react';
import './style.css';
import Selector from "../../components/Selector/Selector";
import Loader from "../../components/Loader/Loader";
import getOrientation from "../../utils/getOrientation";
import getArrayRange from "../../utils/getArrayRange";
import smoothScroll from "../../utils/smoothScroll";
import NoDataBlock from "../../components/NoDataBlock/NoDataBlock";
import Const from "../../utils/Const";

const getMonth = (date) => {
  let month = "";
  switch (date) {
    case 0: month = "янв"; break;
    case 1: month = "фев"; break;
    case 2: month = "мар"; break;
    case 3: month = "апр"; break;
    case 4: month = "май"; break;
    case 5: month = "июн"; break;
    case 6: month = "июл"; break;
    case 7: month = "авг"; break;
    case 8: month = "сен"; break;
    case 9: month = "окт"; break;
    case 10: month = "ноя"; break;
    case 11: month = "дек"; break;
    default: break;
  }
  return month;
};

const getWeekDay = (date) => {
  let weekDay = "";
  switch (date) {
    case 0: weekDay = "вс"; break;
    case 1: weekDay = "пн"; break;
    case 2: weekDay = "вт"; break;
    case 3: weekDay = "ср"; break;
    case 4: weekDay = "чт"; break;
    case 5: weekDay = "пт"; break;
    case 6: weekDay = "сб"; break;
    default: break;
  }
  return weekDay;
};

const goToFullscreen = () => {
  document.documentElement.style.backgroundColor = "white"; //<html>
  document.body.style.backgroundColor = "white";
};

const goToNormalscreen = () => {
  //Check original CSS stylesheet !!!
  document.documentElement.style.backgroundColor = "#eee"; //<html>
  document.body.style.backgroundColor = "unset";
};

const CalendarSpace = ({height}) => {
  return (
    <div
      id={"day-space"}
      style={height ? {height: `${height}px`} : {}}
    />
  );
};

const ScheduleCalendar = ({data, isLoading, screenMode, initDayStyleState, dayStyle, calendarSpaceStyle}) => {
  const {
    initDayStyle,
    setInitDayStyle
  } = initDayStyleState;

  useEffect(() => {
    if (!isLoading) {
      switch (screenMode) {
        case Const.screenModeType.fullscreen:
          goToFullscreen();
          break;
        case Const.screenModeType.normalscreen:
          goToNormalscreen();
          break;
        default:
          break;
      }
    }
  },[screenMode, isLoading]);

  let now = new Date();
  let nowDay = now.getDay();

  let weekSeq = [...Array(data.length).keys()];
  weekSeq.splice(0,nowDay);
  weekSeq = [...weekSeq,...Array(nowDay).keys()];

  return (
    isLoading
      ? (
        <div id={"empty-calendar"}>
          <Loader/>
        </div>
      )
      : (
        data.length > 0
          ? (
            <>
              {data.map((item, itemIndex) => {
                let date = new Date();
                date.setDate(date.getDate() + itemIndex);

                return (
                  <Day
                    key={itemIndex}
                    num={itemIndex}
                    day={date.getDate()}
                    month={getMonth(date.getMonth())}
                    weekDay={getWeekDay(date.getDay())}
                    events={data[weekSeq[itemIndex]].events}
                    setInitStyle={(value) => {
                      setInitDayStyle((prevState) => {
                        let newValue = [...prevState];
                        newValue[itemIndex] = value;
                        return newValue;
                      });
                    }}
                    styles={dayStyle[itemIndex]}
                    screenMode={screenMode}
                  />
                )
              })}
              <CalendarSpace height={calendarSpaceStyle.height}/>
            </>
          )
          : (<NoDataBlock cls={"calendar"} title={"Расписание недоступно"}/>)
      )
  );
};

const ReadPlanCalendar = ({data, isLoading, screenMode, initDayStyleState, dayStyle, calendarSpaceStyle}) => {
  const {
    initDayStyle,
    setInitDayStyle
  } = initDayStyleState;

  useEffect(() => {
    if (!isLoading) {
      switch (screenMode) {
        case Const.screenModeType.fullscreen:
          goToFullscreen();
          break;
        case Const.screenModeType.normalscreen:
          goToNormalscreen();
          break;
        default:
          break;
      }
    }
  },[screenMode, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      smoothScroll("d" + (new Date().getDate()), getOrientation());
    }
  },[isLoading]);

  let now = new Date();
  let nowTime = now.getTime();
  let nowDay = now.getDate();

  let nowIdx = data.findIndex(
    item => new Date(item.day).getDate() === nowDay
      && new Date(item.day).getMonth() === now.getMonth()
      && new Date(item.day).getFullYear() === now.getFullYear()
  );

  let startIdx = 0;
  let endIdx = data.length;
  if (nowIdx !== -1) {
    if (nowIdx-7>startIdx) {
      startIdx = nowIdx-7;
    }

    if (nowIdx+8<endIdx) {
      endIdx = nowIdx+8;
    }
  }

  return (
    isLoading
      ? (
        <div id={"empty-calendar"}>
          <Loader/>
        </div>
      )
      : (
        nowIdx !== -1
          ? (
            <>
              {getArrayRange(startIdx, endIdx).map((item, itemIndex) => {
                if (item<endIdx) {
                  let date = new Date(data[item].day);

                  return (
                    <React.Fragment key={itemIndex}>
                      <DayAnchor id={"d"+date.getDate()}/>
                      <Day
                        num={itemIndex}
                        day={date.getDate()}
                        month={getMonth(date.getMonth())}
                        weekDay={getWeekDay(date.getDay())}
                        events={data[item].events}
                        setInitStyle={(value) => {
                          setInitDayStyle((prevState) => {
                            let newValue = [...prevState];
                            newValue[itemIndex] = value;
                            return newValue;
                          });
                        }}
                        styles={dayStyle[itemIndex]}
                        active={date.getDate() === nowDay}
                        disabled={date.getTime() < nowTime}
                        screenMode={screenMode}
                      />
                    </React.Fragment>
                  )
                } else {
                  return null;
                }
              })}
              <CalendarSpace height={calendarSpaceStyle.height}/>
            </>
          )
          : (<NoDataBlock cls={"calendar"} title={"План отсутствует"}/>)
      )
  );
};

const EventsCalendar = ({data, isLoading, screenMode, initDayStyleState, dayStyle, calendarSpaceStyle}) => {
  const {
    initDayStyle,
    setInitDayStyle
  } = initDayStyleState;

  useEffect(() => {
    if (!isLoading) {
      switch (screenMode) {
        case Const.screenModeType.fullscreen:
          goToFullscreen();
          break;
        case Const.screenModeType.normalscreen:
          goToNormalscreen();
          break;
        default:
          break;
      }
    }
  },[screenMode, isLoading]);

  return (
    isLoading
      ? (
        <div id={"empty-calendar"}>
          <Loader/>
        </div>
      )
      : (
        <NoDataBlock cls={"calendar"} title={"Событий не найдено"}/>
      )
  );
};

const Day = ({num, day, month, weekDay, events, active=false, disabled=false, setInitStyle, styles={}, screenMode}) => {
  const {
    dayContentWrapper={},
    dayLine={},
    dayMiddlePart={},
  } = styles;

  let dayWrapperRef = React.createRef();
  let dayBodyRef = React.createRef();
  let dayContentWrapperRef = React.createRef();
  let dayMiddlePartRef = React.createRef();
  let contentBlockRef = React.createRef();

  useEffect(() => {
    if (contentBlockRef.clientHeight<270) {
      dayWrapperRef.style.height = `${270+45}px`;
    } else {
      dayWrapperRef.style.height = `${contentBlockRef.clientHeight+45}px`;
    }
  });

  useEffect(() => {
    setInitStyle({
      yPos: dayWrapperRef.offsetTop+110,
      dayWrapper: dayWrapperRef,
      dayBody: dayBodyRef,
      dayContentWrapper: dayContentWrapperRef,
      dayMiddlePart: dayMiddlePartRef,
    });
  },[screenMode]);

  return (
    <div
      ref={(elem) => dayWrapperRef = elem}
      className={"day-wrapper"}
    >
      <div
        ref={(elem) => dayBodyRef = elem}
        className={"day-body"}
      >
        <div className={"day-left-part"}>
          <div className={"day-left-content-wrapper"}>
            <div className={"day-left-content-body"}>
              <div
                ref={(elem) => dayContentWrapperRef = elem}
                id={`n${num}`} className={"day-content-wrapper"}
                style={dayContentWrapper}
              >
                <div id={"day-name-wrapper"}>
                  <div id={"day-name-body"}>
                    <div id={"cur-day"} className={"s2"}>{day}</div>
                    <div id={"cur-month"} className={"s4"}>{month}</div>
                    {weekDay ? <div id={"cur-week-day"} className={"s4"}>{weekDay}</div> : <></>}
                  </div>
                </div>
              </div>
            </div>
            <div className={"day-line-wrapper"}>
              <div id={`l${num}`} className="day-line"
                   style={dayLine}
              />
            </div>
          </div>
        </div>
        <div
          ref={(elem) => dayMiddlePartRef = elem}
          className={"day-middle-part"}
          style={dayMiddlePart}
        >
          <div
            ref={(elem) => contentBlockRef = elem}
            className={"day-middle-content-wrapper"}
          >
            {events.length > 0
              ? (events.map((event, eventIndex) => {
                return (
                  <div
                    key={eventIndex}
                    id={active ? "cur-day-event" : disabled ? "old-day-event" : "day-event"}
                    className={"s3"}
                  >
                    <div style={{fontWeight: "bold"}}>
                      {event.time}
                    </div>
                    <div>{`${event.name}\n`}</div>
                  </div>
                );
              }))
              : (<div id={"day-empty"}/>)
            }
          </div>
        </div>
        <div className={"right-part"}>
          <div className={"right-content-wrapper"}>
            <div className={"right-content-body"}/>
          </div>
        </div>
      </div>
    </div>
  );
};

const DayAnchor = ({id}) => {
  return (
    <div id={id} className={"day-anchor"}/>
  );
};

const CalendarLayout = ({calendar, screenMode, orientation, categoryState}) => {
  const {
    categoryId,
    setCategoryId,
    categoryCount
  } = categoryState;

  let multi = 1;
  if(orientation === Const.orientationType.landscape) {
    multi = 2;
  }

  const [pageY, setPageY] = useState(0);
  const [initDayStyle, setInitDayStyle] = useState([]);

  let curDayY = initDayStyle.findIndex(item => item.yPos > pageY);

  const dayStyle = initDayStyle.map((item, index) => {
    let dayContentWrapper = {};
    let dayLine = {};
    let dayMiddlePart = {};

    const unfocusDayLine = "#cccccc";
    const focusDayLine = "#3FA9F5";
    const prevItem = curDayY-2;
    const curItem = curDayY-1;
    const nextItem = curDayY;

    if (curDayY === 0) { //фокус выше начала списка
      //правила для всех обьектов
      dayContentWrapper = {
        paddingTop: 30,
      };
      dayMiddlePart = {
        opacity: screenMode === Const.screenModeType.fullscreen
          ? index === 0 // правило для первого обьекта
            ? 1
            : 0.05
          : undefined,
      };
      dayLine = {
        background: unfocusDayLine,
      };
    } else if (curDayY > 0) { //фокус на обьекте списка
      let condition = 0;
      if (initDayStyle[curItem].dayContentWrapper.clientHeight/2 < 45+30+180+30) {
        condition = initDayStyle[curItem].dayContentWrapper.clientHeight/2;
      } else {
        condition = 45+30+180+30;
      }

      if (
        curDayY !== 1
        && index === prevItem
      ) { //правила для предыдущего обьекта, если он не первый
        dayContentWrapper = {
          paddingTop: item.dayBody.clientHeight-180-30-45,
        };
      }

      if (index === curItem) { //правила для текущего обьекта
        if (pageY >= item.yPos) { //фокус на текущем обьекте или касается его
          dayContentWrapper = {
            paddingTop: pageY+30+180+30+45 < initDayStyle[nextItem].yPos
              ? pageY-item.yPos+30
              : item.dayBody.clientHeight-180-30-45,
          };
          dayLine = {
            background: pageY > initDayStyle[nextItem].yPos-condition
              ? unfocusDayLine
              : focusDayLine,
          };
          dayMiddlePart = {
            opacity: screenMode === Const.screenModeType.fullscreen
              ? pageY > initDayStyle[nextItem].yPos-condition
                ? 0.05
                : 1
              : undefined,
          };
        }
      } else if (index === nextItem) { //правила для следующего обьекта
        if (pageY > item.yPos-condition) { //фокус + амплитуда = следующий обьект
          dayLine = {
            background: focusDayLine,
          };
          dayMiddlePart = {
            opacity: screenMode === Const.screenModeType.fullscreen
              ? 1
              : undefined,
          };
        } else { //фокус на текущем обьекте
          dayContentWrapper = {
            paddingTop: 30,
          };
          dayLine = {
            background: unfocusDayLine,
          };
          dayMiddlePart = {
            opacity: screenMode === Const.screenModeType.fullscreen
              ? 0.05
              : undefined,
          };
        }
      } else { //правила для всех остальных
        dayMiddlePart = {
          opacity: screenMode === Const.screenModeType.fullscreen
            ? 0.05
            : undefined,
        };
      }
    } else if (curDayY === -1) { //фокус на последнем обьекте
      if (index === initDayStyle.length-2) {
        //правила для предпоследнего обьекта
        dayContentWrapper = {
          paddingTop: item.dayBody.clientHeight-180-30-45,
        };
        dayMiddlePart = {
          opacity: screenMode === Const.screenModeType.fullscreen
            ? 0.05
            : undefined,
        };
      } else if (
        index === initDayStyle.length-1
        && pageY >= item.yPos
        && pageY+30+180+30+45 < item.yPos+item.dayWrapper.clientHeight
      ) {
        //правила для последнего обьекта
        dayContentWrapper = {
          paddingTop: pageY-item.yPos+30,
        };
        dayLine = {
          background: focusDayLine,
        };
        dayMiddlePart = {
          opacity: screenMode === Const.screenModeType.fullscreen
            ? 1
            : undefined,
        };
      }
    }

    return {
      dayContentWrapper,
      dayLine,
      dayMiddlePart,
    };
  });

  useEffect(() => {
    document.body.onscroll = () => setPageY(window.pageYOffset*multi + 170+190);

    return function cleanup() {
      document.body.onscroll = null;
    };
  });

  const selectorStyle = {
    [Const.screenModeType.normalscreen] : {},
    [Const.screenModeType.fullscreen] : {
      categoryPanelContainer: {
        top: 0,
        opacity: 0,
      },
      categoryPanel: {
        display: "none",
      },
    },
  };

  const calendarSpaceStyle = {
    [Const.screenModeType.normalscreen] : {
      height: (document.body.clientHeight*multi)-170-190-30-180-30-45-50,
    },
    [Const.screenModeType.fullscreen] : {
      height: (document.body.clientHeight*multi)-170-190-30-180-30-45-50,
    },
  };

  return (
    <>
      <Selector
        title={calendar.category}
        nextClick={() => {
          let next = categoryId+1;
          if (next >= categoryCount) {
            next = 0;
          }
          setCategoryId(next);
        }}
        prevClick={() => {
          let prev = categoryId-1;
          if (prev < 0) {
            prev = categoryCount-1
          }
          setCategoryId(prev);
        }}
        styles={calendar.isLoading
          ? {}
          : selectorStyle[screenMode]
        }
      />
      {{
        ["Служения"]: (
          <ScheduleCalendar
            data={calendar.data}
            isLoading={calendar.isLoading}
            screenMode={screenMode}
            initDayStyleState={{
              initDayStyle,
              setInitDayStyle
            }}
            dayStyle={dayStyle}
            calendarSpaceStyle={calendarSpaceStyle[screenMode]}
          />
        ),
        ["План чтения"]: (
          <ReadPlanCalendar
            data={calendar.data}
            isLoading={calendar.isLoading}
            screenMode={screenMode}
            initDayStyleState={{
              initDayStyle,
              setInitDayStyle
            }}
            dayStyle={dayStyle}
            calendarSpaceStyle={calendarSpaceStyle[screenMode]}
          />
        ),
        ["События"]: (
          <EventsCalendar
            data={calendar.data}
            isLoading={calendar.isLoading}
            screenMode={screenMode}
            initDayStyleState={{
              initDayStyle,
              setInitDayStyle
            }}
            dayStyle={dayStyle}
            calendarSpaceStyle={calendarSpaceStyle[screenMode]}
          />
        ),
      }[calendar.category]}
    </>
  );
};

export default CalendarLayout;