class Constants {
  baseUrl = '';
  timeout = 60000;

  codeOK = 'OK';

  screenModeType = {
    fullscreen: "fullscreen",
    normalscreen: "normalscreen",
  };

  orientationType = {
    landscape: "landscape",
    portrait: "portrait",
  }
}

export const Colors = {
  black: "#333",
  white: "#FFF",
  green: "#31DD9F",
  red: "#FF759E",
  orange: "#F8AC59",
  blue: "#3FA9F5",
};

export const Themes = {
  common: {
    primaryColor: "#3FA9F5",
    secondaryColor: "#1E88E5",
  },
  account: {
    primaryColor: "#F8AC59",
    secondaryColor: "#F79D3C",
  },
  admin: {
    primaryColor: "#F8AC59",
    secondaryColor: "#F79D3C",
  },
};

class ErrorConst {
  NetworkFailed = "Отсутствует подключение к интернету";
  NetworkTimeout = "Превышено время ожидания";
  RequestError = 'Ошибка при выполнении запроса';
  ServerError = 'Сервис в данный момент не доступен';
  InternalError = 'Кажется что-то пошло не так';
}
export const errorConst = new ErrorConst();

class SuccessConst {
  Complete = 'Готово!';
}
export const successConst = new SuccessConst();

const Const = new Constants();
export default Const;