import React from 'react';
import {withRouter} from "react-router-dom";
import './style.css';
import Header, {BackButton, LogoButton} from "../../components/Header/Header";
import AppLayout from "../../layouts/AppLayout/AppLayout";
import AudioLayout from "../../layouts/AudioLayout/AudioLayout";

const AudioScreen = () => {
  return (
    <>
      <Header
        title={"Аудио проповеди"}
        leftOption={<BackButton backHref={"/"}/>}
        rightOption={<LogoButton/>}
      />
      <AppLayout id={"audio"}>
        <AudioLayout/>
      </AppLayout>
    </>
  );
};

export default withRouter(AudioScreen);