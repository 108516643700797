import React from 'react';
import './style.css';
import {withRouter} from "react-router-dom";
import {BackIco} from "../Icons/Icons";
import {Colors} from "../../utils/Const";

export const EmptyButton = () => {
  return (
    <div className={"option-button"} />
  );
};

export const BackButton = withRouter(({
  icon=<BackIco color={Colors.white}/>,
  scale=90,
  history,
  backHref
}) => {
  return (
    <CustomButton
      icon={icon}
      onClick={() => {
        if (backHref) {
          history.replace(backHref);
        } else {
          history.goBack();
        }
      }}
      scale={scale}
    />
  );
});

export const CustomButton = ({icon, onClick=()=>{}, scale}) => {
  return (
    <div className={"option-button"}
         onClick={() => {
           onClick();
         }}
         style={{cursor: "pointer"}}
    >
      {React.Children.map(icon, child => {
        return React.cloneElement(child, {
          ...child.props,
          style: {
            ...child.props.style,
            height: scale, width: scale,
          },
        });
      })}
    </div>
  );
};

export const LogoButton = () => {
  return (
    <div id={"logo"} className={"option-button"} />
  );
};

const Header = ({
  leftOption=<EmptyButton/>,
  rightOption=<EmptyButton/>,
  title,
  styles={}
}) => {
  const {
    navWrapper={},
    navBody={},
  } = styles;

  return (
    <div id={"nav-wrapper"}
         style={navWrapper}
    >
      <div id={"nav"}
           style={navBody}
      >
        {leftOption}
        <div id={"nav-title-body"} className={"s1"}>
          <div id={"nav-title"} className={"vertical-align-middle"}>{title}</div>
        </div>
        {rightOption}
      </div>
    </div>
  );
};

export default Header;