import React, {useState} from "react";
import './style.css';
import {isEmpty} from "lodash";

const BlockWrapper = ({
  cls="block",
  onClick=()=>{},
  behavior={},
  leftContent,
  middleContent,
  rightContent,
  state="common"
}) => {
  const [isMouseEnter, setMouseEnter] = useState(false);

  const stateBehavior = behavior[state] || {};

  const {
    normal={},
    hover={},
  } = stateBehavior;

  const currentStateBehavior = isMouseEnter ? hover : normal;

  return (
    <div className={`${cls}-wrapper`}>
      <div
        className={`${cls}-body${state && state !== "common" ? `-${state}` : ""}`}
        style={{background: currentStateBehavior.background, color: currentStateBehavior.textColor}}
        onClick={() => {
          onClick();
        }}
        onMouseOver={() => {
          if (!isEmpty(behavior)) setMouseEnter(true);
        }}
        onMouseLeave={() => {
          if (!isEmpty(behavior)) setMouseEnter(false);
        }}
      >
        <div className={`${cls}-left-part`}>
          <div className={`${cls}-left-content-wrapper`}>
            <div className={"left-content-body"}>
              {leftContent}
            </div>
          </div>
        </div>
        <div className={`${cls}-middle-part`}>
          <div className={`${cls}-middle-content-wrapper`}>
            <div className={"middle-content-body"}>
              {middleContent}
            </div>
          </div>
        </div>
        <div className={`${cls}-right-part`}>
          <div className={`${cls}-right-content-wrapper`}>
            <div className={"right-content-body"}>
              {rightContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default BlockWrapper;