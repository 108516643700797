import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";
import './style.css';
import Header, {CustomButton} from "../../components/Header/Header";
import AppLayout from "../../layouts/AppLayout/AppLayout";
import MainMenuLayout from "../../layouts/MainMenuLayout/MainMenuLayout";
import {observer} from "mobx-react";
import appStore from "../../stores/AppStore/AppStore";
import {KeyIco} from "../../components/Icons/Icons";

const MainScreen = observer(() => {
  useEffect(() => {
    const fetchData = async () => {
      await appStore.loadAds("index");
    };
    fetchData().then(null);
  }, []);

  return (
    <>
      <Header
        title={"Главная"}
        rightOption={
          <CustomButton
            icon={
              <KeyIco
                color={"#eee"}
                patternColor={"#eee"}
              />
            }
            onClick={() => {
              window.location = "/account";
            }}
          />
        }
      />
      <AppLayout id={"index"}>
        <MainMenuLayout
          ads={appStore.ads}
        />
      </AppLayout>
    </>
  );
});

export default withRouter(MainScreen);