import React from 'react';
import './style.css';
import NoDataBlock from "../../components/NoDataBlock/NoDataBlock";

const AudioLayout = () => {
  return (
    <NoDataBlock cls={"audio"} title={"Аудио нет"}/>
  );
};

export default AudioLayout;