import React from "react";
import './style.css';

const Selector = ({title, nextClick=()=>{}, prevClick=()=>{}, styles={}}) => {
  const {
    categoryPanelContainer={},
    categoryPanel={},
  } = styles;

  return (
    <div id={"category-panel-container"}
         style={categoryPanelContainer}
    >
      <div id={"category-panel"}
           style={categoryPanel}
      >
        <div
          id={"prev-category-btn"} className={"next-nav-btn"}
          onClick={() => {
            prevClick();
          }}
        />
        <div id={"category-title-body"}>
          <div id={"category-title-text"} className={"s3"}>
            {title}
          </div>
        </div>
        <div
          id={"next-category-btn"} className={"next-nav-btn"}
          onClick={() => {
            nextClick();
          }}
        />
      </div>
    </div>
  );
};

export default Selector;