import React from "react";
import '../BlockWrapper/style.css';
import './style.css';
import BlockWrapper from "../BlockWrapper/BlockWrapper";
import PreparedImage from "../PreparedImage/PreparedImage";

const MenuBlock = ({ico, name, onClick, state, behavior}) => {
  return (
    <BlockWrapper
      cls={"menu"}
      behavior={behavior}
      state={state}
      onClick={() => {
        onClick();
      }}
      leftContent={{
        ["string"]: (
          <PreparedImage
            cls={"menu-ico"}
            src={ico}
          />
        ),
        ["object"]: (ico),
      }[typeof ico]}
      middleContent={
        <div className={"menu-name s2"}>
          {name}
        </div>
      }
    />
  );
};

export default MenuBlock;