import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import './style.css';
import Header, {
  BackButton,
  CustomButton,
  EmptyButton,
} from "../../components/Header/Header";
import AppLayout from "../../layouts/AppLayout/AppLayout";
import CalendarLayout from "../../layouts/CalendarLayout/CalendarLayout";
import {observer} from "mobx-react";
import calendarStore from "../../stores/CalendarStore/CalendarStore";
import useQuery from "../../utils/useQuery";
import Const, {Colors} from "../../utils/Const";
import getOrientation from "../../utils/getOrientation";
import {CollapseIco, ExpandIco} from "../../components/Icons/Icons";

const CalendarScreen = observer(() => {
  let query = useQuery();
  const qMode = query.get("mode");
  const [screenMode, setScreenMode] = useState(
    Const.screenModeType[qMode]
      ? Const.screenModeType[qMode]
      : Const.screenModeType.normalscreen
  );

  const [orientation, setOrientation] = useState(getOrientation());

  useEffect(() => {
    window.onresize = () => setOrientation(getOrientation());

    return function cleanup() {
      window.onresize = null;
    };
  });

  const [categoryId, setCategoryId] = useState(0);
  const data = [
    calendarStore.data.schedule,
    calendarStore.data.readPlan,
    calendarStore.data.events
  ];
  /*
  const data = Object.entries(calendarStore.data).map(([key, value]) => {
    return {...value};
  });
  */
  const currentData = data[categoryId];

  useEffect(() => {
    const fetchData = async () => {
      await calendarStore.loadSchedule();
      await calendarStore.loadReadPlan();
      await calendarStore.loadEvents();
    };
    calendarStore.startLoading();
    fetchData().then(null);
  }, []);

  const navStyles = {
    [Const.screenModeType.normalscreen] : {},
    [Const.screenModeType.fullscreen] : {
      navWrapper: {
        zIndex: orientation === Const.orientationType.landscape
          ? "unset"
          : undefined,
      },
      navBody: {
        borderBottom: "10px solid white",
        maxWidth: "unset",
        borderRadius: "unset",
        background: "white",
      },
    },
  };

  const appStyles = {
    [Const.screenModeType.normalscreen] : {},
    [Const.screenModeType.fullscreen] : {
      appWrapper: {
        top: 0,
        maxWidth: orientation === Const.orientationType.landscape
          ? "calc(100% - (175px * 2)"
          : "100%",
      },
      appBody: {
        paddingTop: "0"
      },
    },
  };

  return (
    <>
      <Header
        title={"Календарь"}
        leftOption={
          currentData.isLoading ? <BackButton backHref={"/"}/>
          : {
          [Const.screenModeType.normalscreen]: (
            <BackButton backHref={"/"}/>
          ),
          [Const.screenModeType.fullscreen]: (
            <EmptyButton/>
          ),
        }[screenMode]}
        rightOption={
          currentData.isLoading ? <EmptyButton/>
          : {
          [Const.screenModeType.normalscreen]: (
            <CustomButton
              icon={<ExpandIco color={Colors.white}/>}
              scale={80}
              onClick={() => {
                setScreenMode(Const.screenModeType.fullscreen);
              }}
            />
          ),
          [Const.screenModeType.fullscreen]: (
            <CustomButton
              icon={<CollapseIco color={"#000"}/>}
              scale={80}
              onClick={() => {
                setScreenMode(Const.screenModeType.normalscreen);
              }}
            />
          ),
        }[screenMode]}
        styles={currentData.isLoading
          ? {}
          : navStyles[screenMode]
        }
      />
      <AppLayout
        id={"calendar"}
        styles={currentData.isLoading
          ? {}
          : appStyles[screenMode]
        }
      >
        <CalendarLayout
          calendar={currentData}
          screenMode={screenMode}
          orientation={orientation}
          categoryState={{
            categoryId,
            setCategoryId,
            categoryCount: data.length
          }}
        />
      </AppLayout>
    </>
  );
});

export default withRouter(CalendarScreen);