import React, {useState} from 'react';
import './style.css';
import pSBC from "../../utils/pSBC";

const ButtonBlock = ({cls="app", text="Кнопка", type, onClick=()=>{}, style={}}) => {
  const {
    background="#3FA9F5",
    color="#fff",
    width,
  } = style;
  const [isMouseEnter, setMouseEnter] = useState(false);
  const [isMouseDown, setMouseDown] = useState(false);

  return (
    <button
      className={`${cls}-button s3`}
      type={type}
      style={{
        background: isMouseDown
          ? pSBC(-0.3, background)
          : isMouseEnter
            ? pSBC(0.2, background)
            : background
        ,
        color: color,
        width: width,
      }}
      onMouseEnter={() => {
        setMouseEnter(true);
        setMouseDown(false);
      }}
      onMouseLeave={() => setMouseEnter(false)}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
      onClick={() => {
        onClick();
      }}
    >{text}</button>
  );
};

export default ButtonBlock;