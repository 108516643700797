import React, {useEffect, useState} from 'react';

const PreparedImage = ({src, cls, id, template}) => {
  const [isLoading, setIsLoading] = useState(true);
  let imageElem = React.createRef();

  useEffect(() => {
    let image = new Image();
    image.onload = function() {
      if (imageElem) {
        imageElem.style.backgroundImage = `url(${src})`;
        setIsLoading(false);
      }
    };
    image.src = `${src}`;
  },[src, imageElem]);

  return (
    <>
      {template
        ? isLoading
          ? template
          : null
        : null
      }
      <div
        ref={(elem) => imageElem = elem}
        id={id} className={cls}
        style={template ? isLoading ? {display: "none"} : {} : {}}
      />
    </>
  );
};

export default PreparedImage;