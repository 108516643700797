import React from "react";

const NoDataBlock = ({cls="data", title}) => {
  return (
    <div id={`not-${cls}-wrapper`}>
      <div id={`not-${cls}-body`}>
        <div id={`not-${cls}-ico`}/>
        <div id={`not-${cls}-text`} className={"s1"}>
          {title}
        </div>
      </div>
    </div>
  );
};

export default NoDataBlock;