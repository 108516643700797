import Const from "./Const";

const getOrientation = () => {
  if (window.matchMedia("(orientation: portrait)").matches) {
    return Const.orientationType.portrait;
  }

  if (window.matchMedia("(orientation: landscape)").matches) {
    return Const.orientationType.landscape;
  }
};

export default getOrientation;