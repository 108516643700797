import React from 'react';
import './style.css';

const NotFoundLayout = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
           style={{height: "300px", width: "300px"}}
      >
        <g>
          <g>
            <circle fill={"#d75a4a"} cx="25" cy="25" r="25"/>
            <path fill={"#ffffff"}
                  d="M27.12,25l7.94-7.94a1.5,1.5,0,0,0-2.12-2.12L25,22.88l-7.94-7.94a1.5,1.5,0,0,0-2.12,2.12L22.88,25l-7.94,7.94a1.49,1.49,0,0,0,0,2.12,1.5,1.5,0,0,0,2.12,0L25,27.12l7.94,7.94a1.5,1.5,0,0,0,2.12,0,1.49,1.49,0,0,0,0-2.12Z"/>
          </g>
        </g>
      </svg>
      <div className="error-text-body">
        <div id="error-text" className="s1">404 Страница не найдена</div>
      </div>
      <div className="error-text-body">
        <a id="return-text" className="s1" href="/">Вернуться на главную</a>
      </div>
    </>
  );
};

export default NotFoundLayout;