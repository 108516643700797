import {adminMenu} from "../../stores/AppStore/AppStore";
import React from "react";
import TitleBlock from "../../components/TitleBlock/TitleBlock";
import MenuBlock from "../../components/MenuBlock/MenuBlock";
import {CanvasForIco, GearIco} from "../../components/Icons/Icons";
import {Colors, Themes} from "../../utils/Const";
import {withRouter} from "react-router-dom";

const AdminMenu = ({history}) => {
  return (
    <>
      {adminMenu.map((menu, menuIndex) => {
        return (
          <React.Fragment key={menuIndex}>
            <TitleBlock
              cls={"menu"}
              text={menu.category}
              align={"left"}
            />
            {menu.sections.map((section, sectionIndex) => {
              return (
                <MenuBlock
                  key={sectionIndex}
                  state={section.enabled ? undefined : "disabled"}
                  ico={
                    <CanvasForIco innerSize={120}>
                      <GearIco
                        color={Colors.black}
                        patternColor={Themes.admin.primaryColor}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </CanvasForIco>
                  }
                  name={section.title}
                  onClick={() => {
                    if (section.enabled) {
                      history.push(section.href);
                    }
                  }}
                  behavior={{
                    common: {
                      hover: {
                        background: Themes.admin.primaryColor
                      }
                    }
                  }}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default withRouter(AdminMenu);