import React from 'react';
import './style.css';

const InputBlock = ({cls="app", type, placeholder, value, disabled=false, onChange=()=>{}, style={}}) => {
  const {
    textAlign,
    width,
    padding,
    borderColor,
  } = style;

  return (
    <input
      className={`${cls}-input s4`}
      type={type}
      placeholder={placeholder}
      defaultValue={value}
      disabled={disabled}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      style={{
        textAlign: textAlign,
        width: width,
        padding: padding,
        background: disabled ? "#cccccc30" : undefined,
        borderColor: borderColor
      }}
    />
  );
};

export default InputBlock;