import React from 'react';
import './style.css';

export const LoadingIco = ({style}) => {
  return (
    <div className={"cssload-speeding-wheel"}
         style={style}
    />
  );
};

const Loader = () => {
  return (
    <div className={"cssload-body"}>
      <div className={"cssload-wrapper"}>
        <LoadingIco/>
      </div>
    </div>
  );
};

export default Loader;