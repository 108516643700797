import React from 'react';
import './style.css';

const TitleBlock = ({cls="app", text, align}) => {
  return ({
    "left": (
      <div id={`${cls}-title`} className={"left-title s1"}>
        {text}
      </div>
    ),
    "middle": (
      <div id={`${cls}-title`} className={"middle-title"}>
        <div className={"s1"}>
          {text}
        </div>
      </div>
    ),
  }[align]);
};

export default TitleBlock;