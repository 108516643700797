import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import MainScreen from "./screens/MainScreen/MainScreen";
import OnlineScreen from "./screens/OnlineScreen/OnlineScreen";
import NotFoundScreen from "./screens/NotFoundScreen/NotFoundScreen";
import AudioScreen from "./screens/AudioScreen/AudioScreen";
import NewsScreen from "./screens/NewsScreen/NewsScreen";
import CalendarScreen from "./screens/CalendarScreen/CalendarScreen";
import WhereScreen from "./screens/WhereScreen/WhereScreen";
import DonateScreen from "./screens/DonateScreen/DonateScreen";
import MinistryScreen from "./screens/MinistryScreen/MinistryScreen";
import AccountScreen from "./screens/AccountScreen/AccountScreen";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={["/", ""]} component={MainScreen} />
        <Route path="/account" component={AccountScreen} />
        <Route path="/audio" component={AudioScreen} />
        <Route path="/calendar" component={CalendarScreen} />
        <Route path="/donate" component={DonateScreen} />
        <Route path="/ministry" component={MinistryScreen} />
        <Route path="/news" component={NewsScreen} />
        <Route path="/online" component={OnlineScreen} />
        <Route path="/where" component={WhereScreen} />
        <Route path="*" component={NotFoundScreen} />
      </Switch>
    </Router>
  );
}

export default App;
