import React from 'react';
import './style.css';
import TitleBlock from "../../components/TitleBlock/TitleBlock";

const WhereLayout = () => {
  return (
    <>
      <TitleBlock
        cls={"where"}
        text={"Наш адрес:"}
        align={"left"}
      />
      <div className={"info-text s2"}>
        <a
          href={"https://go.2gis.com/2g5x5w"}
          target={"_blank"}
          rel="noopener noreferrer"
        >г. Кемерово, ул. Заводская, 127</a>
      </div>
      <div id={"map"}/>
      <TitleBlock
        cls={"where"}
        text={"Наши контакты:"}
        align={"left"}
      />
      <div className={"info-text s2"}>
        <a
          href={"tel:+73842570975"}
        >+7 (3842) 57–09–75</a>
        <br/>
        <br/>
        <a
          href={"tel:+79235670975"}
        >+7 (923) 567-09-75</a>
      </div>
    </>
  );
};

export default WhereLayout;