import React from 'react';
import {withRouter} from "react-router-dom";
import './style.css';
import AppLayout from "../../layouts/AppLayout/AppLayout";
import NotFoundLayout from "../../layouts/NotFoundLayout/NotFoundLayout";

const NotFoundScreen = () => {
  return (
    <>
      <AppLayout id={"error"}>
        <NotFoundLayout />
      </AppLayout>
    </>
  );
};

export default withRouter(NotFoundScreen);