import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import onlineStore from "../../stores/OnlineStore/OnlineStore";
import {BrowserRouter as Router, Route, Switch, withRouter} from "react-router-dom";
import OnlineLayout from "../OnlineLayout/OnlineLayout";
import {toJS} from "mobx";
import EditLayout from "./EditLayout";

const OnlineManager = observer(({history}) => {
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await onlineStore.loadData();
    };
    onlineStore.startLoading();
    fetchData().then(null);
  }, [forceUpdate]);

  return (
    <Router basename={"/account/admin/online"}>
      <Switch>
        <Route exact path={["/", ""]} component={withRouter(observer(({history}) => (
          <OnlineLayout
            isLoading={onlineStore.isLoading}
            data={onlineStore.data}
            manageTools={{
              enabled: true,
              addVideo(video) {
                history.push("/edit", {video: toJS(video), mode: "add"});
              },
              editVideo(video) {
                history.push("/edit", {video: toJS(video), mode: "edit"});
              },
              deleteVideo(video) {
                onlineStore.deleteData(video.id_online)
                  .then(() => {
                    setForceUpdate(it => !it);
                  })
                  .catch(() => {
                    console.log("TODO");
                  })
              },
            }}
          />
        )))}/>
        <Route path="/edit" component={EditLayout}/>
        <Route path="*" component={null}/>
      </Switch>
    </Router>
  );
});

export default withRouter(OnlineManager);