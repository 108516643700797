import React from 'react';
import './style.css';
import PromoBlock from "../../components/PromoBlock/PromoBlock";
import MenuBlock from "../../components/MenuBlock/MenuBlock";
import TitleBlock from "../../components/TitleBlock/TitleBlock";
import {appMenu} from "../../stores/AppStore/AppStore";
import {isEmpty} from "lodash";
import {Colors, Themes} from "../../utils/Const";

const MainMenuLayout = ({ads}) => {

  const menuOnClick = (href) => {
    window.location = href;
  };

  return (
    <>
      {isEmpty(ads)
        ? (null)
        : (<div id={"attention"} dangerouslySetInnerHTML={{__html: ads.text}}/>)
      }
      <PromoBlock/>
      <div id={"main-wrapper"}>
        <div id={"main-body"}>
          {appMenu.map((menu, menuIndex) => {
            return (
              <React.Fragment key={menuIndex}>
                <TitleBlock
                  cls={"menu"}
                  text={menu.category}
                  align={"left"}
                />
                {menu.sections.map((section, sectionIndex) => {
                  return (
                    <MenuBlock
                      key={sectionIndex}
                      ico={section.ico}
                      name={section.title}
                      onClick={() => menuOnClick(section.href)}
                      behavior={{
                        common: {
                          hover: {
                            background: Themes.common.primaryColor,
                            textColor: Colors.white,
                          }
                        }
                      }}
                    />
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div id={"main-footer-wrapper"}>
        <div id={"main-footer-body"}>
          <div id={"main-footer-content"} className={"s3"}>
            Местная Религиозная Организация Церковь Евангельских Христиан-Баптистов г.Кемерово<br/><br/>
            ИНН 4205004998 КПП 420501001<br/>
            ОГРН 1034200007291<br/>
            ТЕЛ 89235670975<br/>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainMenuLayout;