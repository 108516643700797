import React, {useEffect, useState} from 'react';
import './style.css';
import Loader from "../../components/Loader/Loader";
import {DefaultPhoto, MoreBtn} from "../../components/Icons/Icons";
import BlockWrapper from "../../components/BlockWrapper/BlockWrapper";
import PreparedImage from "../../components/PreparedImage/PreparedImage";

const DonateLink = () => {
  return (
    <div
      id={"pay-link"} className={"s3"}
      onClick={() => {
        window.location = "/donate";
      }}
    >
      {"Пожертвование >"}
    </div>
  );
};

const MinistryBlock = ({ico, text, isOpen, onClick}) => {
  return (
    <BlockWrapper
      cls={"ministry"}
      onClick={() => {
        onClick();
      }}
      leftContent={
        <PreparedImage
          cls={"ministry-list-ico"}
          src={ico}
        />
      }
      middleContent={
        <div className={"ministry-name s2"}>
          {text}
        </div>
      }
      rightContent={null}
      /*rightContent={
        <MoreBtn
          style={isOpen ? {transform: "rotate(90deg)"} : {transform: "rotate(0deg)"}}
        />
      }*/
    />
  );
};

const DescriptionBlock = ({children, text, isOpen}) => {
  const [initHeight, setInitHeight] = useState();
  const closeHeight = 38;
  let descriptionWrapper = React.createRef();

  useEffect(() => {
    setInitHeight(descriptionWrapper.clientHeight);
  },[]);

  return (
    <div
      ref={(elem) => descriptionWrapper = elem}
      className={"descr-wrapper"}
      style={initHeight
        ? isOpen
          ? {height: `${initHeight}px`}
          : {height: `${closeHeight}px`}
        : {}
      }
    >
      <div className={"descr-body"}>
        <div className={"descr-text s3"}>
          {text}
        </div>
        {children}
      </div>
    </div>
  );
};

const PersonBlock = ({service, fio, photo, phone, background="#eeeeee"}) => {
  return (
    <BlockWrapper
      cls={"person"}
      background={background}
      leftContent={
        <PreparedImage
          cls={"person-photo"}
          src={photo}
          template={<DefaultPhoto/>}
        />
      }
      middleContent={
        <div className={"person-info s2"}>
          {service}
          <hr id={"person-hr"}/>
          {fio}
          {phone
            ? (
              <>
                <hr id={"person-hr"}/>
                {phone}
              </>
            )
            : (null)
          }
        </div>
      }
    />
  );
};

const MinistryLayout = ({isLoading, data, seq}) => {
  const [isOpen, setIsOpen] = useState(Array(data.length).fill(false));

  useEffect(() => {
    setIsOpen(Array(data.length).fill(false));
  },[data]);

  return (
    isLoading
      ? (
        <div id={"empty-ministry"}>
          <Loader/>
        </div>
      )
      : (data.length>0
        ? (
          <>
            <DonateLink/>
            {data.map((item, itemIndex) => {
              const currItem = data[seq[itemIndex]];
              const currOpen = isOpen[seq[itemIndex]];

              return (
                <React.Fragment key={itemIndex}>
                  <MinistryBlock
                    key={itemIndex}
                    ico={currItem.ico}
                    text={currItem.ministry}
                    isOpen={currOpen}
                    onClick={() => {}}
                    /*onClick={() => {
                      setIsOpen(isOpen.map((item, index) => {
                        return index === seq[itemIndex]
                          ? !item
                          : item
                        ;
                      }));
                    }}*/
                  />
                  {/*<DescriptionBlock
                    text={currItem.description}
                    isOpen={currOpen}
                  >
                    {currItem.leader.map((leader, leaderIndex) => {
                      return (
                        <PersonBlock
                          key={leaderIndex}
                          service={"Координатор"}
                          fio={currItem.leader[leaderIndex].fio}
                          photo={currItem.leader[leaderIndex].photo}
                          //phone={currItem.leader[leaderIndex].phone}
                          background={"#eeeeee"}
                        />
                      );
                    })}
                    {currItem.branch.map((branch, branchIndex) => {
                      return (
                        <PersonBlock
                          key={branchIndex}
                          service={currItem.branch[branchIndex].ministry}
                          fio={currItem.branch[branchIndex].leader.fio}
                          photo={currItem.branch[branchIndex].leader.photo}
                          //phone={currItem.branch[branchIndex].leader.phone}
                          background={"transparent"}
                        />
                      );
                    })}
                  </DescriptionBlock>*/}
                </React.Fragment>
              );
            })}
            <DonateLink/>
          </>
        )
        : (<></>)
      )
  );
};

export default MinistryLayout;