import React from 'react';
import './style.css';
import {BrowserRouter as Router, Route, Switch, withRouter} from "react-router-dom";
import AdminMenu from "./AdminMenu";
import OnlineManager from "./OnlineManager";

const AdminLayout = () => {
  return (
    <Router basename={"/account/admin"}>
      <Switch>
        <Route exact path={["/", ""]} component={AdminMenu}/>
        <Route path="/online" component={OnlineManager}/>
        <Route path="*" component={null}/>
      </Switch>
    </Router>
  );
};

export default withRouter(AdminLayout);