import React, {useEffect, useState} from 'react';
import './style.css';
import InputBlock from "../../components/InputBlock/InputBlock";
import ButtonBlock from "../../components/ButtonBlock/ButtonBlock";
import TitleBlock from "../../components/TitleBlock/TitleBlock";
import {LoadingIco} from "../../components/Loader/Loader";
import {errorConst} from "../../utils/Const";

const AuthLayout = ({onAuth, isLoading, error}) => {
  const [isValid, setIsValid] = useState(true);
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    if (error) setIsValid(false);
  },[error]);

  return (
    <div className={"auth-wrapper"}>
      <form className={"auth-body"}
            autoComplete={"on"}
      >
        <TitleBlock
          text={isValid
            ? "Авторизация"
            : error
              ? error
              : errorConst.InternalError
          }
          align={"middle"}
        />
        <InputBlock
          type={"login"}
          placeholder={"логин"}
          disabled={isLoading}
          onChange={(value) => {
            setIsValid(true);
            setLogin(value);
          }}
          style={{
            textAlign: "center",
            borderColor: isValid ? undefined : "#F08080",
            width: "calc(80% - 20px)",
          }}
        />
        <InputBlock
          type={"password"}
          placeholder={"пароль"}
          disabled={isLoading}
          onChange={(value) => {
            setIsValid(true);
            setPassword(value);
          }}
          style={{
            textAlign: "center",
            borderColor: isValid ? undefined : "#F08080",
            width: "calc(80% - 20px)",
          }}
        />
        {isLoading
          ? (
            <LoadingIco
              style={{
                height: 60,
                width: 60,
              }}
            />
          )
          : (
            <ButtonBlock
              text={"Войти"}
              type={"submit"}
              style={{
                background: "#3FA9F5",
                color: "#fff",
                width: "calc(50% - 30px*2)",
              }}
              onClick={() => {
                setIsValid(true);
                onAuth(login, password);
              }}
            />
          )
        }
      </form>
    </div>
  );
};

export default AuthLayout;